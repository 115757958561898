import { Alert, Button, Checkbox, Form, Input, Typography } from 'antd';
import { Link, navigate } from 'gatsby';
import { replace } from 'lodash';
import omit from 'lodash/omit';
import React, { useState } from 'react';

import EmptyLayout from '../components/layout/EmptyLayout';
import SearchEngineOptimization from '../components/utility/seo';
import { useCustomer } from '../hooks/use-customer';

const { Text } = Typography;

interface RegisterForm {
  first_name: string;
  last_name: string;
  company: string;
  email: string;
  phone: string;
  password: string;
  sign_up_note: string;
}

const SignUp = () => {
  const [form] = Form.useForm<RegisterForm>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const {
    actions: { createCustomer, updateCustomerDetails },
  } = useCustomer();

  const setErrorByType = (type: string) => {
    switch (type) {
      case 'duplicate_error':
        setError('อีเมลนี้ได้มีการเปิดใช้งานไปแล้ว กรุณาเลือกเข้าสู่ระบบแทน');
        break;
      default:
        setError(
          'ขออภัย พบปัญหาในการสมัครสมาชิกของคุณได้ กรุณาตรวจสอบข้อมูลของคุณและลองใหม่อีกครั้ง'
        );
    }
  };

  const onSubmit = async (formValues: RegisterForm) => {
    try {
      setLoading(true);
      const response = await createCustomer(
        omit(formValues, ['company', 'policy', 'sign_up_note'])
      );
      if (response.error) {
        setErrorByType(response.error?.type);
        return;
      }
      await updateCustomerDetails({
        metadata: {
          company: formValues.company,
          sign_up_note: formValues.sign_up_note,
        },
      });
      navigate('/sign-up-pending');
    } catch (_) {
      setErrorByType('');
    } finally {
      setLoading(false);
    }
  };

  return (
    <EmptyLayout title={'สมัครสมาชิก TSA'}>
      <SearchEngineOptimization title="Sign Up" />
      <Form
        form={form}
        onFinish={onSubmit}
        layout="vertical"
        autoComplete="off"
        disabled={loading}
      >
        <div className="flex">
          <Form.Item
            name="first_name"
            label="ชื่อ"
            required={false}
            rules={[{ required: true, message: 'กรุณากรอกชื่อ' }]}
          >
            <Input />
          </Form.Item>
          <div className="w-[16px]"></div>
          <Form.Item
            name="last_name"
            label="นามสกุล"
            required={false}
            rules={[{ required: true, message: 'กรุณากรอกนามสกุล' }]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item name="company" label="บริษัท">
          <Input />
        </Form.Item>
        <Form.Item
          name="email"
          label="อีเมล"
          required={false}
          rules={[
            { required: true, message: 'กรุณากรอกอีเมล' },
            { type: 'email', message: 'กรุณากรอกอีเมลให้ถูกต้อง' },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="เบอร์ติดต่อ"
          required={false}
          rules={[{ required: true, message: 'กรุณากรอกเบอร์ติดต่อ' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="รหัสผ่าน"
          required={false}
          rules={[
            { required: true, message: 'กรุณากรอกรหัสผ่าน' },
            { min: 8, message: 'อย่างน้อย 8 ตัวอักษร' },
          ]}
        >
          <Input.Password placeholder="ระบุรหัสผ่านอย่างน้อย 8 ตัวอักษร" />
        </Form.Item>
        <Form.Item name="sign_up_note" label="Note (Optional)">
          <Input />
        </Form.Item>
        <Form.Item
          name="policy"
          valuePropName="checked"
          rules={[
            {
              validator(rule, value, callback) {
                if (!value) {
                  callback('กรุณากดยอมรับเงื่อนไข');
                  return;
                }
                callback();
              },
            },
          ]}
        >
          <Checkbox>
            <Text className="!text-light-primary">
              {`ข้าพเจ้าได้อ่านและยอมรับ `}
            </Text>
            <Link to="/privacy">นโยบายความเป็นส่วนตัว</Link>
            <Text className="!text-light-primary">{` และ `}</Text>
            <Link to="/terms-and-conditions">ข้อกำหนดและเงื่อนไข</Link>
            <Text className="!text-light-primary">
              {` ในการสมัครสมาชิกกับ TSA`}
            </Text>
          </Checkbox>
        </Form.Item>
        <Button type="primary" loading={loading} htmlType="submit" block>
          สมัครสมาชิก
        </Button>
      </Form>
      <div className="mt-[16px] flex justify-center">
        <Text className="mr-[5px]">มีบัญชีอยู่แล้ว?</Text>
        <Link to="/sign-in">เข้าสู่ระบบ</Link>
      </div>
      {error && (
        <Alert
          className="!mt-4 !items-baseline !text-light-title"
          message={error}
          type="error"
          showIcon
        />
      )}
    </EmptyLayout>
  );
};

export default SignUp;
